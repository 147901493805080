import Vue 		from 'vue'
import Router 	from 'vue-router'
import store 	from './store.js'

import DashboardLayout 	from "@/layouts/Dashboard/Layout.vue";
import HomeLayout	 	from "@/layouts/HomePage/Layout.vue";
import BuilderLayout 	from "@/layouts/Builder/Layout.vue";

import Home 	 	from '@/views/Home/Welcome.vue'
// import About 	 	from '@/views/Home/About.vue'
import ContactUs 	from '@/views/Home/ContactUs.vue'
import Pricing	 	from '@/views/Home/Pricing.vue'
import page_404		from '@/views/404'
import PrivacyPolicy	from '@/views/Home/PrivacyPolicy.vue'
import TermsOfService	from '@/views/Home/TermsOfService.vue'

// Auth
import Login 	from '@/views/Auth/Login.vue'
import Register from '@/views/Auth/Register.vue'
import Forgot 	from '@/views/Auth/Forgot.vue'
import Reset 	from '@/views/Auth/Reset.vue'

import Dashboard 	from '@/views/User/Dashboard/Dashboard.vue'
// List -----------------------
import Sources 		from '@/views/User/Lists/Sources.vue'
import NewSource	from '@/views/User/Lists/NewSource.vue'
import MyLists 		from '@/views/User/Lists/MyLists.vue'
import ListSetting	from '@/views/User/Lists/Settings.vue'
// Account --------------------
import MyProfile	from '@/views/User/Account/MyProfile.vue'
import MySettings	from '@/views/User/Account/MySettings.vue'
// Resources ------------------
import Domains 		from '@/views/User/Resources/Domains.vue'
import Senders 		from '@/views/User/Resources/Senders.vue'
import FbPages 		from '@/views/User/Resources/FbPages.vue'
// Campaigns ------------------
import CreateCampaign 	from '@/views/User/Campaigns/CreateCampaign.vue'
import MyCampaigns		from '@/views/User/Campaigns/MyCampaigns.vue'
import FbCampaigns		from '@/views/User/Campaigns/FbCampaigns.vue'
// Leads ----------------------
import MyLeads			from '@/views/User/Leads/MyLeads.vue'
import MyContacts		from '@/views/User/Leads/MyContacts.vue'
// Support --------------------
import MyTickets		from '@/views/User/HelpCenter/MyTickets.vue'
import NewTicket		from '@/views/User/HelpCenter/NewTicket.vue'

import TeamInvitation 	from '@/views/Auth/TeamInvitation.vue'

import Billing			from '@/views/User/Payment/Billing.vue'

// Builder pages
import MyWorks 		from '@/views/Builder/home/my-works'
import MyTemplate 	from '@/views/Builder/home/my-template'
import TemplateList from '@/views/Builder/home/template-list'
import Editor 		from '@/views/Builder/editor/Index'

Vue.use(Router)

let router = new Router({
	history: true,
	mode: 'history',
	routes: [
		// Users Pages
		{ path: "/on", component: DashboardLayout, redirect: "/dashboard", 
		children: [
			{ path: "/dashboard", 		name: "dashboard",	component: Dashboard,	meta: { requiresAuth: true, title: 'Dashboard' } },
			{ path: '/sources', 		name: 'sources', 	component: Sources,		meta: { requiresAuth: true, title: 'Sources' 	} },
			{ path: '/source/:source',  name: 'source', 	component: NewSource,	meta: { requiresAuth: true, title: 'New Source' } },
			{ path: '/lists',	 		name: 'lists',		component: MyLists,		meta: { requiresAuth: true, title: 'My Lists' } },
			{ path: '/list/:list_id',	name: 'listSetting',component: ListSetting,	meta: { requiresAuth: true, title: 'List Setting' } },
			
			{ path: '/domains',	 		name: 'domains',	component: Domains,		meta: { requiresAuth: true, title: 'My Domains' } },
			{ path: '/senders',	 		name: 'senders',	component: Senders,		meta: { requiresAuth: true, title: 'My Senders' } },
			{ path: '/fb/pages', 		name: 'fb.pages',	component: FbPages,		meta: { requiresAuth: true, title: 'Facebook Pages' } },
			
			{ path: '/campaign/create', 	  name: 'campaign.create',	component: CreateCampaign,	meta: { requiresAuth: true, title: 'Create Campaign' } },
			{ path: '/campaign/create/:type', name: 'create',			component: CreateCampaign,	meta: { requiresAuth: true, title: 'Create Campaign' } },
			{ path: '/email/campaigns',		  name: 'email.campaigns',	component: MyCampaigns,		meta: { requiresAuth: true, title: 'Email Campaigns' } },
			{ path: '/fb/campaigns',	 	  name: 'fb.campaigns',		component: FbCampaigns,		meta: { requiresAuth: true, title: 'Facebook Campaigns' } },
			{ path: '/profile', 			  name: 'profile',			component: MyProfile,		meta: { requiresAuth: true, title: 'My Profile' } },
			{ path: '/settings', 			  name: 'settings',			component: MySettings,		meta: { requiresAuth: true, title: 'My Settings' } }, // Teams & Members
			{ path: '/settings/:tab',		  name: 'settings.tab',		component: MySettings,		meta: { requiresAuth: true, title: 'My Settings' } }, // Teams & Members
			{ path: '/help', 				  name: 'help',				component: MyTickets,		meta: { requiresAuth: true, title: 'Help Center' } },
			{ path: '/ticket', 				  name: 'ticket',			component: NewTicket,		meta: { requiresAuth: true, title: 'Submit a ticket' } },
			{ path: '/billing', 			  name: 'billing',			component: Billing,			meta: { requiresAuth: true, title: 'Invoices & Funds' } },
			
			{ path: '/leads', 				  name: 'leads',			component: MyLeads,			meta: { requiresAuth: true, title: 'My Leads' } },
			{ path: '/leads/:list',			  name: 'leadsList',		component: MyLeads,			meta: { requiresAuth: true, title: 'Leads' 	  } },
			{ path: '/contacts', 			  name: 'contacts',			component: MyContacts,		meta: { requiresAuth: true, title: 'My Contacts' } },
		] },
		
		// Global Pages
		{ path: "/", component: HomeLayout, redirect: "home", /* meta: { requiresAuth: undefined },  */
		children: [
			// Users Pages
			{ path: '/', 				name: 'home', 		component: Home,  		meta: { requiresAuth: undefined, title: 'Welcome' } },
			{ path: '/welcome/:token', 	name: 'welcome', 	component: Home,  		meta: { requiresAuth: undefined, title: 'Welcome back' } },
			// { path: 'about', 			name: 'about', 		component: About, 		meta: { requiresAuth: undefined, title: 'About' } },
			{ path: '/contactus',		name: 'contactus', 	component: ContactUs, 	meta: { requiresAuth: undefined, title: 'Contact us' } },
			{ path: '/pricing', 		name: 'pricing',	component: Pricing,		meta: { requiresAuth: undefined, title: 'Pricing' } },
			{ path: 'privacy-policy',	name: 'privacy-policy', 	component: PrivacyPolicy, 	meta: { requiresAuth: undefined, title: 'Privacy Policy' } },
			{ path: 'terms-of-service',	name: 'terms-of-service', 	component: TermsOfService, 	meta: { requiresAuth: undefined, title: 'Terms of Service' } },
		] },
		
		// Builder Pages
		{ path: "/builder", component: BuilderLayout, redirect: "/builder/works", /* meta: { requiresAuth: undefined },  */
		children: [
			{ path: 'works', 		 name: 'myworks',		component: MyWorks, 		meta: { requiresAuth: true, title: 'My Works' } },
			{ path: 'templates', 	 name: 'mytemplate', 	component: MyTemplate, 		meta: { requiresAuth: true, title: 'My Templates' } },
			{ path: 'template-list', name: 'templatelist', 	component: TemplateList, 	meta: { requiresAuth: true, title: 'Template List' } },
		] },
		{ path: '/builder/editor', 		 name: 'Editor', 	component: Editor, 			meta: { requiresAuth: true, title: 'Editor' } },
		
		// { path: '/builder', name: 'Home', component: () => import('@/views/Builder/home/index'), redirect: {name: 'myWorks'},
		// },
		
		// Auth Pages
		{ path: '/login', 	 		name: 'login', 		component: Login, 	 meta: { requiresAuth: false, 	title: 'Login' } },
		{ path: '/register', 		name: 'register', 	component: Register, meta: { requiresAuth: false, 	title: 'Register' } },
		{ path: '/forgot', 	 		name: 'forgot', 	component: Forgot, 	 meta: { requiresAuth: false, 	title: 'Forgot My Password' } },
		{ path: '/reset/:token',	name: 'reset', 		component: Reset, 	 meta: { requiresAuth: false, 	title: 'Reset my password' } },
		{ path: '*',		 		name: '404', 		component: page_404, meta: { requiresAuth: undefined, title: 'Page 404' } },
		// meta: { auth: {roles: 2, redirect: {name: 'login'}, forbiddenRedirect: '/403'} }
		
		{ path: '/team/invitation/:token', 	name: 'teaminvitation', 	component: TeamInvitation, 	 meta: { requiresAuth: undefined, title: 'Team Invitation' } },
	]
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title + ' | SenderLead' || '';
	if(to.matched.some(record => record.meta.requiresAuth)) {
		// const user = store.state.user
		if (store.getters.isLoggedIn) {
			next()
			return
		} else { next('/login') }
	} else if ( to.meta.requiresAuth == false ) {
		if( !store.getters.isLoggedIn ) {
			next()
		} else { next('/dashboard') }
	} else if (to.meta.requiresAuth == undefined) {
		next()
	} else {
		next('/login')
	}
})

export default router
