
const mixin = {
	data: () => ({
		
	}),
	methods: {
		// capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1)
		ToastNotify(variant = '', title = '', content) {
			let that = this
			that.$notify({
				group	: 'ToastNotify',
				title	: title,
				text	: content,
				variant	: variant,
				duration: 5000,
				// speed	: 1000
			});
		},
		
		CopyText(StringToCopy) {
			let that = this
			try {
				navigator.clipboard.writeText(StringToCopy)
				that.ToastNotify('success', 'Copied !')
			} catch (err) {
				console.error('Failed !');
			}
			
			// Method 2 -----------------------------------
			// this.selectText( document.getElementById(element) )
			// document.execCommand("copy");
			
			// Method 3 -----------------------------------
			// let selectEl =  document.getElementById(element)
			// selectEl.select();
			// document.execCommand("copy");
		},
		
		/* selectText(element) {
			var range;
			if (document.selection) {
				// IE
				range = document.body.createTextRange();
				range.moveToElementText(element);
				range.select();
			} else if (window.getSelection) {
				range = document.createRange();
				range.selectNode(element);
				window.getSelection().removeAllRanges();
				window.getSelection().addRange(range);
			}
		}, */
		
		isIsset (string) {
			return (typeof string !== 'undefined') && !['', null].includes(string)
		},
		
		ErrorHandler (response) {
			let that = this
			if( that.isIsset(response) ) {
				if( that.isIsset(response.data) && that.isIsset(response.data.errors) ) {
					let errors = response.data.errors
					Object.keys(errors).forEach(function(field) {
						that.ToastNotify('warning', 'Opps!', errors[field][0])
					})
				} else if( that.isIsset(response.data) && that.isIsset(response.data.message) ) { //  response.status == 403 && response.statusText == 'Forbidden' &&
					that.ToastNotify('warning', 'Opps !', response.data.message)
				} else {
					that.ToastNotify('warning', 'Opps !', 'Something wrong, please try again')
				}
				return false
			}
		},
		
		ChangeConfig(data) {
			
			let that = this
			let request = { url: '/user/config', method: 'POST', data: data }
			that.$store.dispatch('requests', request).then(() => { // response
			
				that.$store.state.user.config[data['action']] = data['content']
				let user = JSON.stringify(that.$store.state.user)
				localStorage.setItem('sl-user', user )
				
			}).catch(({response}) => {
				that.Loading.changeplan = false
				that.ErrorHandler(response)
			} )
			
		},
		
		max_length(string, Length = 25) {
			let feedback = ''
			if( string != null && string != '' ) {
				feedback = Length > 0 ? string.length > Length ? string.substring(0, Length) + "..." : string : string
			}
			return feedback
		}
		
	},
	filters: {
		date_format: function(str) {
			let mydate = str
			if (!mydate) { return '(n/a)'; }
			mydate = new Date(mydate)
			// mydate = mydate.getFullYear() + '-' + ((mydate.getMonth() < 9) ? '0' : '') + (mydate.getMonth() + 1) + '-' + ((mydate.getDate() < 10) ? '0' : '') + mydate.getDate()
			mydate = mydate.toLocaleDateString('en-GB', { // en-US - en-GB - en
				month	: 'long', // short
				day		: 'numeric',
				year	: 'numeric',
				hour	: '2-digit',
				minute	: '2-digit'
			}).split(',').join(' ')
			return mydate + 'h'
			
			// YYYY-MMM-DDTHH:MM:SS 
			// .format("YYYY-MM-DD")
			// YYYY.MM.DD HH:mm:ss
			// new Date().toLocaleDateString('en-US', {
			// day: 'numeric',
			// month: 'short',
			// year: 'numeric',
			// });
		},
		MaxLength: function (string) {
			if( string != null && string != '' ) {
				return string.length > 25 ? string.substring(0, 25) + "..." : string
			}
			return ''
		},
		capitalize: function (string) {
			if( string != null && string != '' ) {
				return string.charAt(0).toUpperCase() + string.slice(1)
			} else {
				return ''
			}
		},
		NumberFormat (Number) {
			if ((typeof Number !== 'undefined') && !['', null].includes(Number) ) {
				return Number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
			} else {
				return ''
			}
			
		}
	}
}

export default mixin;
