import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

// Builder -------------------------------------------
import $config from "@/config/Builder/index";
import editor from './Builder/editor'
axios.defaults.baseURL = $config.baseURL
// Builder -------------------------------------------


Vue.prototype.$http = axios;

const token = localStorage.getItem('sl-token')
if (token) {
	Vue.prototype.$http.defaults.headers.common = {
		'Accept': 'application/json',
		'Authorization': 'Bearer ' + localStorage.getItem('sl-token')
	};
}

const store = new Vuex.Store({
	state: {
		status		: '',
		token		: localStorage.getItem('sl-token') || '',
		user 		: JSON.parse( localStorage.getItem('sl-user') ) || '',
		loading 	: true,
		selectedPage: '',
		FundsModal	: false,
		PlansModal	: false,
		
		AddFunds	: { Amount: '25', NewAmount: '25', Currency: 'USD' },
		MyTeams		: [],
		TeamSelected: '',
		CounterData	: { lists: 0, leads: 0, 'email.campaigns': 0, 'fb.campaigns': 0, domains: 0, senders: 0 },
		
		isCollapsed	: true,
		onBoard		: false
	},
	mutations: {
		auth_request(state) {
			state.status = 'loading'
		},
		auth_success(state, token, user) {
			state.status = 'success'
			state.token  = token
			state.user   = typeof( user ) != 'undefined' ? JSON.parse( user ) :  JSON.parse( localStorage.getItem('sl-user') )
		},
		auth_error(state) {
			state.status = 'error'
		},
		logout(state) {
			state.status = ''
			state.token  = ''
			state.user  = ''
		},
	},
	actions: {
		login({commit}, user) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios.post( '/login', user ).then(resp => {
					const token = resp.data.user.api_token
					const user = JSON.stringify(resp.data.user)
					localStorage.setItem('sl-token', token)
					localStorage.setItem('sl-user', user )
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
					commit('auth_success', token, user)
					resolve(resp)
				}).catch(err => {
					commit('auth_error')
					localStorage.clear()
					reject(err)
				})
			})
		},
		register({commit}, user){
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios.post( '/register', user ).then(resp => {
					const token = resp.data.user.api_token
					const user = JSON.stringify(resp.data.user)
					localStorage.setItem('sl-token', token)
					localStorage.setItem('sl-user', user )
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
					commit('auth_success', token, user)
					resolve(resp)
				}).catch(err => {
					commit('auth_error', err)
					localStorage.clear()
					
					reject(err)
				})
			})
		},
		logout({commit}){
			return new Promise((resolve, reject) => {
				axios.post( '/logout' ).then(resp => {
					localStorage.clear()
					delete axios.defaults.headers.common['Authorization']
					commit('logout')
					resolve(resp)
				}).catch(err => {
					commit('auth_error')
					reject(err)
				})
			})
		},
		
		requests({commit}, request){
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios( request ).then(resp => {
					resolve(resp)
				}).catch(err => {
					if( typeof(err.response) != 'undefined' && typeof(err.response.data) != 'undefined' && (err.response.data.message == 'Unauthenticated.' || err.response.statusText == 'Unauthorized') ) {
						store.dispatch('logout').then(() => {
							location.reload();
						})
					} else {
						commit('auth_error')
						reject(err)
					}
				})
			})
		},
		
		CheckTeams() {
			store.state.loading = true
			let request = { url: '/teams/get', method: 'GET', data: {} }
			store.dispatch('requests', request).then((response) => {
				store.state.MyTeams = []
				let feedback = response.data, role = ''
				store.state.TeamSelected = feedback.current_team
				feedback.teams.forEach( function(team) {
					if( team.id == feedback.default_team ) {
						role = 'default'
					} else if( team.owner == feedback.user_id ) {
						role = 'owner'
					} else {
						role = team.admins_ids.includes(feedback.user_id) ? 'admin' : team.editors_ids.includes(feedback.user_id) ? 'editor' : 'viewer'
					}
					store.state.MyTeams.push({ option: team.id, title: team.name, tag: role })
				} )
				
				store.state.loading = false
			}).catch((  ) => { // {response}
				
			} )
			
		},
		
		SidebarCounter() {
			store.state.loading = true
			let request = { url: '/account/counter', method: 'GET', params: {} }
			store.dispatch('requests', request).then((response) => {
				let feedback = response.data
				store.state.CounterData = feedback
				store.state.loading = false
			}).catch((  ) => { // {response}
				
			} )
			
		}
		
	},
	getters : {
		isLoggedIn	: state => !!state.token,
		authStatus	: state => state.status,
		// authUser	: state => state.user,
	},
	modules: {
		editor
	}
})

export default store
